import { render, staticRenderFns } from "./langWidget.vue?vue&type=template&id=73944397&scoped=true&"
import script from "./langWidget.ts?vue&type=script&lang=ts&"
export * from "./langWidget.ts?vue&type=script&lang=ts&"
import style0 from "./langWidget.vue?vue&type=style&index=0&id=73944397&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73944397",
  null
  
)

export default component.exports